import { GetterTree } from "vuex";

const getters: GetterTree<any, any> = {
    user(state: any): any {
      return state.user;
    },
    config(state: any): any {
      return state.config;
    },
    routers(state: any): any {
      return state.routers;
    }
};

export default getters;