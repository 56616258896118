import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'babel-polyfill';

import md5 from 'js-md5';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from "./utils/http"
import ajax from "./utils/ajax"
import utils from "./utils/utils"
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueClipboard from 'vue-clipboard2'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper-bundle.css'
import 'swiper/css/swiper.css'

Vue.use(ElementUI)
Vue.use(VueQuillEditor)
Vue.use(VueClipboard)
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$ajax = ajax
Vue.prototype.$utils = utils
Vue.prototype.$md5 = md5;
Vue.prototype.$v = '123456';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
