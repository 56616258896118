






// @ is an alias to /src
import { Component,Vue,Provide } from "vue-property-decorator";
import { State, Getter, Mutation, Action } from "vuex-class";

@Component({
  components: {}
})
export default class Footer extends Vue{
    // 是否打开登录Dialog页面
    @Provide() dgLoginVisible: boolean = false;

    @Getter('user') getUser: any;
    @Getter('config') siteConfig: any;
    created(){
        // console.log(this.getUser.admin_name);
    }    
    userCommand(command: string):void{
        if(command == 'logout'){
            localStorage.removeItem('tsToken');
            this.$router.replace('/login');
        } else if(command == 'usercenter'){
            console.log('用户中心');
        }
    }
}
