import { ActionTree } from "vuex";
import jwt_decode from "jwt-decode";

const actions: ActionTree<any,any> = {
    async setUser({state,commit},user:any){
        // const decoded = jwt_decode(user);
        commit('SET_USER',user);
    },
    async setConfig({state,commit},config:any){
        commit('SET_CONFIG',config);
    }
};

export default actions;