





























// @ is an alias to /src
import { Component, Vue, Provide, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Content extends Vue {
  // 面包屑的数组
  @Provide() breadCrumbItems: any;

  // 定义监听器
  @Watch("$route") handleRouteChange(to: any) {
    this.initBreadCrumbItems(to);
  }

  // created勾子函数
  created() {
    this.initBreadCrumbItems(this.$route);
  }

  initBreadCrumbItems(router: any) {
    // 根路由title
    let breadCrumbItems: any = [{ path: "/home", title: "后台管理系统" }];

    //遍历父级到当前子路由的页面的title和path 存储到数组里
    for (const index in router.matched) {
      if (router.matched[index].meta && router.matched[index].meta.title) {
        breadCrumbItems.push({
          path: router.matched[index].path ? router.matched[index].path : "/",
          title: router.matched[index].meta.title
        });
      }
    }
    
    this.breadCrumbItems = breadCrumbItems;
  }
}
