






// @ is an alias to /src
import { Component,Vue } from "vue-property-decorator";
import { State, Getter, Mutation, Action } from "vuex-class";

@Component({
  components: {}
})
export default class App extends Vue{
  // 存储用户信息
  @Action("setUser") setUser: any;
  // 存储配置信息
  @Action("setConfig") setConfig: any;
  created(){
    this.setUser(localStorage.tsToken);
    // utils.checkBrowser();
    this.getSystemConfig();
  }

  // 获取配置信息
  getSystemConfig() {
    let forms = {
        m: "base.getSystemConfig",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let config = res.data.data.config;
          this.setConfig(config);

          var link = document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = config.webico;
          document.getElementsByTagName('head')[0].appendChild(link);
          let webtitle = config['webtitle'] != '' ? config['webtitle'] : '奥宇商城';
          let webkeywords = config['webkeywords'] != '' ? config['webkeywords'] : '奥宇商城';
          document.title = webtitle;
          let utils = (this as any).$utils;
          var objs = document.getElementsByTagName("meta");
          for(var i=0; i < objs.length; i++){
              if(objs[i].name === 'keywords' || objs[i].name === 'description'){
                objs[i].content = webkeywords;
              }
          }
          // utils.checkBrowser(config);
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
