import Vue from 'vue'
var utils = {
    // 清空对象属性的值
    // Object.keys(obj)、Object.values(obj)、Object.entries(obj)
    objectClear(obj: any) {
        let entries = Object.entries(obj);
        entries.forEach(el => {
            el[1] = '';
        });
        return entries;
    },
    // 将undefined,null等转化为
	praseStrEmpty(str:String) {
		if(!str || str=="undefined" || str=="null"){
			return "";
		}
		return str;
	},
	/**
	* 校验只要是数字（包含正负整数，0以及正负浮点数）就返回true
	**/
	isNumber(val: any){
		var regs = /^(\d+)(\.\d+)?$/;
		if(regs.test(val)){
			return true;
		}else{
			return false;
		}
	},
	checkEmail(email:any){ 
	    if(!(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/.test(email))){
	        return false; 
	    }
		return true;
	},
	checkMobile(mobile:any){ 
	    if(!(/^1[0-9]{10}$/.test(mobile))){
	        return false; 
	    }
		return true;
	},
	getSmsCode(_that: any,param: any,method:String = 'user.getSmsCode'){
		let p = {
			m: method,
			mobile: this.praseStrEmpty(param.mobile),
			sms_type: this.praseStrEmpty(param.sms_type),
			verify_code: this.praseStrEmpty(param.verify_code),
			domain_check: this.praseStrEmpty(param.domain_check),
			domain_name: this.praseStrEmpty(param.domain_name),
		};
		(_that as any).$ajax.httpPost(
			"/api/api/router",
			p,
			(res: any) => {
			  if (res.data.code === 0) {
				let currentTime = 60;
				_that.time = currentTime + '秒';
				_that.codeDis = true;				
				let interval = setInterval(function () {
					_that.time = (currentTime - 1) + '秒';
					currentTime--;
					if (currentTime <= 0) {
					  clearInterval(interval);
					  _that.time = '重新获取';
					  _that.codeDis = false;
					  currentTime = 60
					  _that.getCaptcha();
					}
				}, 1000)
			  } else {
			    _that.$message({
			      message: res.data.msg,
			      type: "error"
				});
				_that.getCaptcha();
			  }
			},
			(err: any) => {
			  console.log(err);
			}
		);
	},
	// type:1 首页 2:商品详情页 3:商品列表页
	navMobile(type:Number,id:String) {
		if(this.isMobile()) {
			if(type == 1) {
				window.location.replace(`mobile/index.html`);
			} else if(type == 2) {
				window.location.replace(`mobile/index.html#/pages/goods_dtl/goods_dtl?id=${id}`);
			} else if(type == 3) {
				window.location.replace(`mobile/index.html#/pages/goods_list/goods_list?cat_id=${id}`);
			}
		}
	},
	isMobile() {
		var sUserAgent = navigator.userAgent.toLowerCase();
		if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
			return true;
		} else {
			return false;
		}
	},
	checkBrowser(config: any) {
		var sUserAgent = navigator.userAgent.toLowerCase();
		let origin = window.origin || window.location.origin;
		let url = origin.toLowerCase();
		let merPos = window.location.href.toLocaleLowerCase().indexOf('merchant');
		if(merPos > -1)
			return;
		if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
			//跳转移动端页面			
			// if( url.indexOf(config.src_h5_url) === -1 && url.indexOf(config.trg_h5_url) === -1) {
			// 	// let navUrl = url.replace(/faka.shay360/,'h5.shay360');
			// 	window.location.href = config.src_h5_url;		
			// }
			if(config.level_url){
				window.location.replace(`${config.level_url}/mobile`);
			} else {
				window.location.href = `${url}/mobile`;
			}
		} else {
			//跳转pc端页面
			// if( url.indexOf(config.src_pc_url) === -1 && url.indexOf(config.trg_pc_url) === -1 ) {
			// 	// let navUrl = url.replace(/h5.shay360/,'faka.shay360');
			// 	window.location.href = config.src_pc_url;
			// }
			if(config.level_url) {
				window.location.replace(`${config.level_url}`);
			}
		}
	}
}

export default utils;