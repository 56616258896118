
























// @ is an alias to /src
import { Component, Vue,Provide } from "vue-property-decorator";
import { State, Getter, Mutation, Action } from "vuex-class";
import actions from '../../store/actions';
import getters from '../../store/getters';

@Component({
  components: {}
})
export default class Aside extends Vue {
  @Getter("routers") getRouters: any;
  @Provide() routers:any = [{
    module_id: "1",
    module_title:'我是买家',
    module_icon: 'iconfont icon-woshimaijia1',
    children:[{
      module_title:'网站首页',
      path:'/'
    },{
      module_title:'会员中心',
      path:'default'
    },{
      module_title:'我的订单',
      path:'myOrder'
    }]
  },{
    module_id: "2",
    module_title:'我的分站',
    module_icon: 'iconfont icon-UPMSquanxianicon-',
    children:[{
      module_title:'分站配置',
      path:'stationConfig'
    },{
      module_title:'商品管理',
      path:'userGoods'
    },{
      module_title:'用户订单',
      path:'userOrder'
    },{
      module_title:'广告管理',
      path:'userAd'
    },{
      module_title:'文章管理',
      path:'userArticle'
    }]
  },{
    module_id: "3",
    module_title:'财务管理',
    module_icon: 'iconfont icon-caiwu',
    children:[{
      module_title:'在线充值',
      path:'/'
    },{
      module_title:'财务明细',
      path:'/'
    },{
      module_title:'申请提现',
      path:'/'
    },{
      module_title:'提现记录',
      path:'/'
    }]
  },{
    module_id: "4",
    module_title:'账号安全',
    module_icon: 'iconfont icon-anquan',
    children:[{
      module_title:'提现设置',
      path:'/'
    },{
      module_title:'修改密码',
      path:'/'
    },{
      module_title:'修改支付密码',
      path:'/'
    }]
  },{
    module_id: "5",
    module_title:'推广分销',
    module_icon: 'iconfont icon-tuiguang1',
    children:[{
      module_title:'我的推广',
      path:'/'
    },{
      module_title:'我的下级',
      path:'/'
    },{
      module_title:'佣金明细',
      path:'/'
    }]
  }];
  created(){
    this.loadUCModuleList();
  }
  loadUCModuleList() {
    let p = {
        m: "base.getUCModuleList"
    };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      p,
      (res: any) => {
        if (res.data.code === 0) {          
          this.routers = res.data.data.module_list;
        } else {
        //   this.$message({
        //     message: res.data.msg,
        //     type: "error"
        //   });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
