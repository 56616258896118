

























// @ is an alias to /src
import { Component,Vue,Prop,Provide } from "vue-property-decorator";
import { State, Getter, Mutation, Action } from "vuex-class";

@Component({
  components: {}
})
export default class Header extends Vue{
    // 是否打开登录Dialog页面
    @Provide() member_name: String = '';
    // @Provide() siteConfig: any = [];
    @Prop() siteConfig!:[];

    @Getter('user') getUser: any;
    created(){
        this.member_name = localStorage.member_name;
        // this.getSystemConfig();
        // console.log(this.getUser.admin_name);
    }
    // 导航到用户中心
    navUserCenter() {
        if(this.$route.path != '/default')
            this.$router.replace('/usercenter');
        // this.$router.push('usercenter');
    }
    // 返回首页
    toHome() {
        this.$router.push("/");
    }
    //退出登录
    logout() {
        let p = {
            m: "user.logout"
        };
        (this as any).$ajax.httpPost(
          "/api/api/router",
          p,
          (res: any) => {
            if (res.data.code === 0) {
              localStorage.removeItem('tsToken');
            localStorage.removeItem('member_name');
            this.$router.push("/");
            } else {
                // 匿名
            }
          },
          (err: any) => {
            console.log(err);
          }
        );
    }
    // 获取配置信息
    // getSystemConfig() {
    //     let forms = {
    //         m: "base.getSystemConfig",
    //         cat_id: 1
    //     };
    //     (this as any).$ajax.httpPost(
    //     "/api/api/router",
    //     forms,
    //     (res: any) => {
    //         if (res.data.code === 0) {
    //         this.siteConfig = res.data.data.config;
    //         document.title = this.siteConfig.webtitle;
    //         }
    //     },
    //     (err: any) => {
    //         console.log(err);
    //     }
    //     );
    // }    
    userCommand(command: string):void{
        if(command == 'logout'){
            localStorage.removeItem('tsToken');
            this.$router.replace('/login');
        } else if(command == 'usercenter'){
            console.log('用户中心');
        }
    }
}
