import Vue from 'vue'
var ajax = {
    httpPost(url:String,param: any,successResponse: any,failResponse: any,isLoading:boolean = true){
      // if(url == '')
      // url = `${location.origin}${url}`;
      // console.log('url',url);
      if(isLoading == false) {
        localStorage.setItem('isLoading','0');
      } else {
        localStorage.setItem('isLoading','1');
      }
      let p = param;
      delete p.sign;
      if(this.praseStrEmpty(localStorage.tsToken) != ''){
        p.token = localStorage.tsToken
      }
      p.sign = this.makeSign(p);
      setTimeout(() => {
        Vue.prototype.$axios
        .post(url, p)
        .then(successResponse)
        .catch(failResponse)
      }, 50);
    },
    makeSign(params:any){
      var v = Vue.prototype.$v;      
      let signdata = this.getSignData(params)
      return Vue.prototype.$md5(v + signdata + v)
    },
    getSignData(json:any) {
      let arr = [];
      for (var key in json) {
        arr.push(key)
      }
      arr.sort();
      let str = '';
      for (let i in arr) {
        str += arr[i] + "=" + this.praseStrEmpty(json[arr[i]]) + "&"
      }
      return str.substr(0, str.length - 1)
    },
    // 将undefined,null等转化为
    praseStrEmpty(str:String) {
      if(!str || str=="undefined" || str=="null"){
        return "";
      }
      return str;
    }
}

export default ajax;