import Vue from 'vue'
import VueRouter from 'vue-router'
import { nextTick } from 'vue/types/umd';
import UserCenter from '../views/layout/UserCenter.vue'

Vue.use(VueRouter)

/**
  * hidden: true                   如果hidden为true则在左侧菜单栏展示，否则不显示
  * name:'router-name'             路由名称，必须填写
  * meta : {
      title: 'title'               对应路由在左侧菜单栏的标题名称
      icon: 'icon-class'           对应路由在左侧菜单栏的图标样式，样式使用fontawesome图标库
    }
  **/
export const asyncRouterMap = [
  {
    path: '/',
    name: 'route',
    component: () => import("@/views/index/Route.vue")
  },
  {
    path: '/index',
    name: 'index',
    component: () => import("@/views/index/Index.vue")
  },
  {
    path: '/home',
    name: 'home',
    component: () => import("@/views/index/Home.vue")
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import("@/views/goods/Goods.vue")
  },
  {
    path: '/goodsList',
    name: 'goodsList',
    component: () => import("@/views/index/goodsList.vue")
  },
  {
    path: '/categoryList',
    name: 'categoryList',
    component: () => import("@/views/index/categoryList.vue")
  },
  {
    path: '/order',
    name: 'order',
    component: () => import("@/views/goods/Order.vue")
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import("@/views/goods/Pay.vue")
  },
  {
    path: '/orderReturn',
    name: 'orderReturn',
    component: () => import("@/views/goods/OrderReturn.vue")
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import("@/views/merchant/Merchant.vue")
  },
  {
    path: '/touristorder',
    name: 'touristorder',
    component: () => import("@/views/index/TouristOrder.vue")
  },
  {
    path: '/usercenter',
    name: 'usercenter',
    component: UserCenter,
    redirect: "/default",
    children:[
      {
        path: '/default',
        name: 'default',    
        component: () => import("@/views/usercenter/Default.vue")
      },
      {
        path: '/myOrder',
        name: 'myOrder',    
        component: () => import("@/views/usercenter/MyOrder.vue")
      },
      {
        path: '/stationConfig',
        name: 'stationConfig',    
        component: () => import("@/views/usercenter/StationConfig.vue")
      },
      {
        path: '/userGoods',
        name: 'userGoods',    
        component: () => import("@/views/usercenter/UserGoods.vue")
      },
      {
        path: '/userOrder',
        name: 'userOrder',    
        component: () => import("@/views/usercenter/UserOrder.vue")
      },
      {
        path: '/batchOrder',
        name: 'batchOrder',    
        component: () => import("@/views/usercenter/BatchOrder.vue")
      },
      {
        path: '/userArticle',
        name: 'userArticle',    
        component: () => import("@/views/usercenter/UserArticle.vue")
      },
      {
        path: '/userAd',
        name: 'userAd',    
        component: () => import("@/views/usercenter/UserAd.vue")
      },
      {
        path: '/stationNotice',
        name: 'stationNotice',    
        component: () => import("@/views/usercenter/StationNotice.vue")
      },
      {
        path: '/userStation',
        name: 'userStation',    
        component: () => import("@/views/subcenter/UserStation.vue")
      },
      {
        path: '/subMember',
        name: 'subMember',    
        component: () => import("@/views/subcenter/SubMember.vue")
      },
      {
        path: '/subMemberChargeList',
        name: 'subMemberChargeList',    
        component: () => import("@/views/subcenter/SubMemberChargeList.vue")
      },
      {
        path: '/subMemberWithDrawList',
        name: 'subMemberWithDrawList',    
        component: () => import("@/views/subcenter/SubMemberWithDrawList.vue")
      },
      {
        path: '/userCharge',
        name: 'userCharge',    
        component: () => import("@/views/financial/UserCharge.vue")
      },
      {
        path: '/userConsume',
        name: 'userConsume',    
        component: () => import("@/views/financial/UserConsume.vue")
      },
      {
        path: '/userCommission',
        name: 'userCommission',    
        component: () => import("@/views/financial/UserCommission.vue")
      },
      {
        path: '/userWithdraw',
        name: 'userWithdraw',    
        component: () => import("@/views/financial/UserWithdraw.vue")
      },
      {
        path: '/withdrawConfig',
        name: 'withdrawConfig',    
        component: () => import("@/views/security/WithdrawConfig.vue")
      },
      {
        path: '/mySpread',
        name: 'mySpread',    
        component: () => import("@/views/spread/mySpread.vue")
      },
    ]
  },
  {
    path: '/articleList',
    name: 'articleList',
    component: () => import("@/views/article/ArticleList.vue")
  },
  {
    path: '/article',
    name: 'article',
    component: () => import("@/views/article/Article.vue")
  },
  {
    path: '/404',
    name: '404',
    hidden: false,
    meta: { title: '404' },
    component: () => import('@/views/404.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: asyncRouterMap
})

router.beforeEach((to: any, from: any, next: any) => {
  const isLogin = localStorage.tsToken ? true : false;
  next();
  // if (to.path == '/login') {
  //   next();
  // } else {
  //   isLogin ? next() : next('/login');
  // }
})

export default router
