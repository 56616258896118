


















// @ is an alias to /src
import { Component,Vue,Provide } from "vue-property-decorator";
import Header from "./Header.vue";
import Content from "./Content.vue";
import Aside from "./Aside.vue";
import Footer from "./Footer.vue";

@Component({
  components: {Header,Content,Aside,Footer}
})
export default class UserCenter extends Vue{
  @Provide() config: any = {};
  created() {
    this.config = this.$store.state.config;
  }
}
